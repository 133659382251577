<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="700px"
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-title
          >{{ $t("sentFileOnechat.header") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">mdi-chat</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <br />
          <div class="text-center">
            <v-chip class="pt-4 pl-2 ma-2"
              ><p>
                <v-icon :color="color.theme"> people_alt</v-icon
                ><b> {{ count_user }} คน </b>
              </p></v-chip
            >
          </div>
          <v-list>
            <!--โชว์ friend ที่เลือกมา -->
            <v-list-item v-for="item in showtosend" :key="item.title">
              <v-list-item-action
                @mouseover="item.show_name = true"
                @mouseleave="item.show_name = false"
              >
                <v-icon large>
                  {{ item.user_icon }}
                </v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title
                  @mouseover="item.show_name = true"
                  @mouseleave="item.show_name = false"
                >
                  {{ item.display_name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-progress-linear
                    v-if="
                      check_show_progress === true &&
                        item.send_value !== 100 &&
                        item.send_value !== 500
                    "
                    :value="item.send_value"
                    color="light-blue"
                  ></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- โชว์สถานะการส่งว่าส่งสำเร็จหรือไม่ -->
              <v-list-item-action>
                <v-chip
                  v-if="item.send_value !== 500"
                  class="ma-2 font-weight-bold"
                  :color="color.theme"
                  label
                  outlined
                >
                  {{ item.send_status }}
                </v-chip>
                <v-chip
                  v-else
                  class="ma-2 font-weight-bold"
                  color="red"
                  label
                  outlined
                >
                  {{ item.send_status }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- ปุ่มก่อนกดยืนยันที่จะส่งหรือกดปิดdialog -->
          <v-btn
            v-if="check_show_progress === false && check_show_button === false"
            color="red"
            outlined
            @click="$emit('closedialog'), (countuser = [])"
            >{{ $t("sentFileOnechat.close") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            v-if="check_show_progress === false && check_show_button === false"
            :dark="color.darkTheme"
            @click="
              fn_sendonechat(),
                (check_show_button = true),
                (check_show_progress = true),
                (countuser = [])
            "
            >{{ $t("sentFileOnechat.send") }}</v-btn
          >
          <!-- ส่งเสร็จจะโชว์ปุ่มนี้เพื่อกดปิด-->
          <v-btn
            color="red"
            :disabled="check_dis_button"
            v-if="check_show_button === true"
            dark
            @click="$emit('closedialogreload'), (countuser = [])"
            >{{ $t("sentFileOnechat.close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{
                $t("sentFileOnechat.header")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="$emit('closedialog'), (countuser = [])"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text>
          <div class="text-center">
            <v-chip class="pt-4 pl-2 mx-2"
              ><p>
                <v-icon :color="color.theme"> people_alt</v-icon
                ><b> {{ count_user }} คน </b>
              </p></v-chip
            >
          </div>
          <v-list>
            <!--โชว์ friend ที่เลือกมา -->
            <v-list-item v-for="item in showtosend" :key="item.title">
              <v-list-item-action
                @mouseover="item.show_name = true"
                @mouseleave="item.show_name = false"
              >
                <v-icon large>
                  {{ item.user_icon }}
                </v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title
                  @mouseover="item.show_name = true"
                  @mouseleave="item.show_name = false"
                >
                  {{ item.display_name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-progress-linear
                    v-if="
                      check_show_progress === true &&
                        item.send_value !== 100 &&
                        item.send_value !== 500
                    "
                    :value="item.send_value"
                    color="light-blue"
                  ></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- โชว์สถานะการส่งว่าส่งสำเร็จหรือไม่ -->
              <v-list-item-action>
                <v-chip
                  v-if="item.send_value !== 500"
                  class="ma-2 font-weight-bold"
                  :color="color.theme"
                  label
                  outlined
                >
                  {{ item.send_status }}
                </v-chip>
                <v-chip
                  v-else
                  class="ma-2 font-weight-bold"
                  color="red"
                  label
                  outlined
                >
                  {{ item.send_status }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <div class="text-center my-4">
          <!-- ปุ่มก่อนกดยืนยันที่จะส่งหรือกดปิดdialog -->
          <v-btn
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="$emit('closedialog'), (countuser = [])"
            v-if="check_show_progress === false && check_show_button === false"
            >{{ $t("sentFileOnechat.close") }}</v-btn
          >
          <v-btn
            height="40px"
            width="40%"
            class="elevation-0"
            :style="btnAction"
            :color="color.theme"
            dark
            @click="summitsendonechat(), $emit('openDrag')"
            v-if="check_show_progress === false && check_show_button === false"
            >{{ $t("sentFileOnechat.send") }}</v-btn
          >
          <!-- ส่งเสร็จจะโชว์ปุ่มนี้เพื่อกดปิด-->
          <v-btn
            color="red"
            :disabled="check_dis_button"
            v-if="check_show_button === true"
            dark
            @click="$emit('closedialogreload'), (countuser = [])"
            >{{ $t("sentFileOnechat.close") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  props: ["show", "filedata", "checkfriend"],
  data: function() {
    return {
      countuser: [],
      count_user: 0,
      check_dis_button: true,
      check_show_progress: false,
      check_show_button: false,
      showtosend: [],
      count_item: 0,
      countshowtosend: 0,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        // this.$emit("closeDrag");
        this.fn_loaddata();
        this.check_show_button = false;
        console.log(this.filedata);
        console.log("checkfriend", this.checkfriend);
      } else {
        // this.$emit("openDrag");

        this.check_show_progress = false;
        this.showtosend = [];
        this.count_item = 0;
      }
    },
  },
  methods: {
    fn_loaddata() {
      for (let i = 0; i < this.checkfriend.length; i++) {
        this.countuser.push(this.checkfriend[i]);
        console.log("this.countuser", this.countuser);
        this.count_user = this.countuser.length;

        this.count_item++;
        console.log(this.checkfriend[i]);
        let datauser = {};
        datauser["display_name"] = this.checkfriend[i]["display_name"];
        datauser["one_id"] = this.checkfriend[i]["one_id"];
        datauser["user_icon"] = "people_alt";
        datauser["send_status"] = this.$t("sentFileOnechat.choosefriend");
        datauser["email"] = this.checkfriend[i]["email"];
        datauser["is_active"] = this.checkfriend[i]["is_active"];
        datauser["type"] = this.checkfriend[i]["type"];
        datauser["send_value"] = 0;
        datauser["show_name"] = false;

        this.showtosend.push(datauser);
      }
      console.log("this.showtosend", this.showtosend);
      this.countshowtosend = this.showtosend.length;
    },
    //ฟังก์ชั่นวนส่งไฟล์
    async fn_sendonechat() {
      for (let i = 0; i < this.checkfriend.length; i++) {
        let objIndex = this.showtosend.findIndex(
          (obj) => obj.one_id === this.checkfriend[i].one_id
        );
        this.showtosend[objIndex]["send_status"] = this.$t(
          "sentFileOnechat.sendding"
        );
        let payload = {
          account_id: this.dataAccountId,
          receiver_id: this.checkfriend[i].one_id,
          file_id: this.filedata.file_id,
        };
        console.log(payload);

        await this.fn_send(
          payload,
          this.checkfriend[i].one_id,
          this.checkfriend[i]
        ).then(async (res) => {
          //  if(res.status === "send to onechat success"){
          //  }else{
          //  }
        });

        if (this.checkfriend.length - i === 1) {
          this.check_dis_button = false;
        }
      }
    },
    //ส่ง
    async fn_send(payload, one_id) {
      let objIndex = this.showtosend.findIndex((obj) => obj.one_id === one_id);
      let auth = await gbfGenerate.generateToken();
      let result = await this.axios({
        method: "POST",
        url: process.env.VUE_APP_SERVICE_ONECHAT + "/api/sendchat",
        data: payload,
        headers: { Authorization: auth.code },

        onUploadProgress: (e) => {
          if ((e.loaded / e.total) * 100 >= 95) {
            this.showtosend[objIndex]["send_value"] = 95;
          } else {
            this.showtosend[objIndex]["send_value"] = parseInt(
              (e.loaded / e.total) * 100
            );
          }
        },
      });
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            this.showtosend[objIndex]["send_status"] = this.$t(
              "sentFileOnechat.sendfile"
            );
            this.showtosend[objIndex]["send_value"] = 100;
            setTimeout(() => {
              resolve({
                status: "send to onechat success",
                msg: "OK",
                code: "",
              });
            }, 500);
          } else {
            this.showtosend[objIndex]["send_status"] = result.data.errorMessage;
            this.showtosend[objIndex]["send_value"] = 500;
            setTimeout(() => {
              resolve({
                status: "send to onechat denied",
                msg: ": " + result.errorMessage,
                code: "",
              });
            }, 500);
          }
        } catch (err) {
          setTimeout(() => {
            resolve({
              status: "send to onechat denied",
              msg: ": " + err,
              code: "",
            });
          }, 500);
          // this.showtosend[objIndex]["send_status"] = this.$t('dialogmultidelete.deletefail');
          this.showtosend[objIndex]["send_status"] = err;
          this.showtosend[objIndex]["send_value"] = 500;
        }
      });
    },
  },
};
</script>
<style></style>
